import React, { Component } from 'react'
import Link from '../utils/link'
import Newsletter from './newsletter'
import nsw from '../assets/images/nsw.png'
import aca from '../assets/images/aca.png'
import rise from '../assets/images/rise.png'
import city from '../assets/images/city.png'

class Header extends Component {

  state = {
    info: false,
    subscribe: false,
    contact: false
  }

  componentDidMount() {
    this.setVH()
  }

  _hideOffCanvas = () => {
    document.body.classList.remove('no-scroll')
    this.setState({ 
      info: false,
      subscribe: false,
      contact: false,
    })
  }

  setVH = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  _showInfo = () => {
    document.body.classList.add('no-scroll')
    this.setVH()
    this.setState({ info: true })
  }
  _showSubscribe = () => {
    document.body.classList.add('no-scroll')
    this.setVH()
    this.setState({ subscribe: true })
  }
  _showContact = () => {
    document.body.classList.add('no-scroll')
    this.setVH()
    this.setState({ contact: true })
  }

  render() {

    let { info, subscribe, contact } = this.state

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <nav className='header__nav'>
              <ul>
                <li>
                  <button onClick={this._showInfo}>
                    Info
                  </button>
                </li>
                <li>
                  <button onClick={this._showSubscribe}>
                    Subscribe
                  </button>
                </li>
                <li>
                  <button onClick={this._showContact}>
                    Contact
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div className={`off-canvas off-canvas--info ${ info && 'active' }`}>
          <div className='off-canvas__inner'>
            <button className='off-canvas__close' onClick={this._hideOffCanvas}>
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.831543 0.709839L25.2615 25.1398" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M25.2615 0.709839L0.831543 25.1398" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
              </svg>
            </button>
            <div className='off-canvas__content'>
              <p>Percussion Australia is the centre of a thriving community of artists, educators, students, supporters, and collaborators. Home to two globally recognised music ensembles Synergy Percussion and Taikoz,  leading taiko training facility the Australian Taiko Academy, and music festival ‘Boom! International Festival of Percussion’. We enable, encourage and produce collaborative projects, new compositions, public performances, workshops, classes, festivals, pre-professional training and ensembles plus more.</p>
              <ul>
                <li><Link to='https://www.taikoz.com/'>TAIKOZ ↗</Link></li>
                <li><Link to='https://www.synergypercussion.com/'>SYNERGY ↗</Link></li>
                <li><Link to='https://boomfestival.com.au/'>BOOM FESTIVAL ↗</Link></li>
                <li><Link to='https://give-aus.keela.co/skin-in-the-game-support-our-people/'>SUPPORT US ↗</Link></li>
              </ul>
            </div>
            <div className='off-canvas__footer'>
              <Link to='//'><img src={nsw} alt='Percussion Australia - NSW' /></Link>
              <Link to='//'><img src={aca} alt='Percussion Australia - ACA' /></Link>
              <Link to='//'><img src={rise} alt='Percussion Australia - Rise' /></Link>
              <Link to='//'><img src={city} alt='Percussion Australia - City' /></Link>
            </div>
          </div>
        </div>
        <div className={`off-canvas off-canvas--subscribe ${ subscribe && 'active' }`}>
          <div className='off-canvas__inner'>
            <button className='off-canvas__close' onClick={this._hideOffCanvas}>
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.831543 0.709839L25.2615 25.1398" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M25.2615 0.709839L0.831543 25.1398" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
              </svg>
            </button>
            <div className='off-canvas__content'>
              <Newsletter />
            </div>
            <div className='off-canvas__footer'>
              <ul>
                { false && <li><Link to='/privacy-policy'>PRIVACY POLICY ↗</Link></li> }
              </ul>
            </div> 
          </div>
        </div>
        <div className={`off-canvas off-canvas--contact ${ contact && 'active' }`}>
          <div className='off-canvas__inner'>
            <button className='off-canvas__close' onClick={this._hideOffCanvas}>
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.831543 0.709839L25.2615 25.1398" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M25.2615 0.709839L0.831543 25.1398" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
              </svg>
            </button>
            <div className='off-canvas__content'>
              <div>
                <p>Percussion Australia PO Box 284<br />
                Camperdown NSW 1450 Australia</p>
              </div>
              <div>
                <h4>Ian Cleworth</h4>
                <span>Artistic Director</span>
                <Link to='mailto:ad@percussionaustralia.com.au'>ad@percussionaustralia.com.au ↗</Link>
              </div>
              <div>
                <h4>Lee McIver</h4>
                <h5>General Manager | Producer</h5>
                <Link to='mailto:gm@percussionaustralia.com.au'>gm@percussionaustralia.com.au ↗</Link>
              </div>
              <div>
                <h4>Karen Steains</h4>
                <h5>Finance Director</h5>
                <Link to='mailto:fd@percussionaustralia.com.au'>fd@percussionaustralia.com.au ↗</Link>
              </div> 
            </div>
            <div className='off-canvas__footer'>
              <ul>
                <li><Link to='https://www.instagram.com/percussion_australia/?hl=en'>INSTAGRAM ↗</Link></li>
                <li><Link to='https://www.facebook.com/PercussionAustralia'>FACEBOOK ↗</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
