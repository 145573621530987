import React, { Component } from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Newsletter extends Component {
  state = {
    email: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    fetch("https://hooks.zapier.com/hooks/catch/2181815/bcghus3/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: 'success'})
      })
      .catch(error => this.setState({ result: "fail", msg: error }))
  }

  render() {
    let props = {
      name: "newsletter",
      className: "form form--newsletter",
      onSubmit: this.handleSubmit,
    }

    if (this.state.result === "success")
      return (
        <p className="contact__success">Thanks for subscribing.</p>
      )

    return (
      <form {...props}>
        <input 
          type='email' name='email' 
          placeholder='Your email address' 
          onChange={this.handleChange}
          required 
        />
        <button type='submit'>
          Subscribe
        </button>
      </form>
    )
  }
}

export default Newsletter